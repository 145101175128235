import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useCMSState } from '@internal/useCMSHook';
import Layout from '../layout/layout';
import { Blades } from '../common/blades/Blades';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { HrefLangs } from '../layout/refreshed-header/HrefLangs';
import { GlobalStrings } from '../../common/fragments/MRKConfig';
import { ContentfulPage } from '../../contentful/content-types/page/page.interface';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from 'src/contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!) {
    page(relativePath: { eq: "home-gatsby" }, locale: { eq: $locale }) {
      ...pageFragment
    }
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    globalStrings: mrkConfigStaticStrings(
      contentfulId: { eq: "1uP0ac1EPF1qsviUi4I9Vz" }
      locale: { eq: $locale }
    ) {
      ...GlobalStringsFragment
    }
  }
`;
export interface HomePageParams extends GatsbyTemplateProps<AppContextProps> {
  data: {
    page: ContentfulPage;
    footerData: MainFooter;
    headerData: ContentfulNavNavigationGroup;
    globalStrings: GlobalStrings;
  };
}

const HomepagePage: FC<HomePageParams> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { footerData, headerData, globalStrings, page } = data;
  if (config.targetEnv === 'staging') {
    globalStrings = useCMSState<GlobalStrings>(globalStrings);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    footerData = useCMSState<MainFooter>(footerData);
    page = useCMSState<ContentfulPage>(page);
  }

  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>{`${page.metaTitle} | Cloudflare`}</title>
        <meta name="title" content={`${page.metaTitle} | Cloudflare`} />
        <meta name="description" content={page.metaDescription} />
        {HrefLangs(pageContext.locale, pageContext.pathname)}
      </Helmet>
      <Blades blades={page.relatedBlades} globalStrings={globalStrings}></Blades>
    </Layout>
  );
};

export default HomepagePage;
